<template>
    <div class="main">
        <div class="pa-3">
        <v-card class="mx-auto align-content-start" max-width="500">
            <v-flex xs12 style="padding: 5px 30px 10px 30px;">
                <h4>Change password for: {{user}}</h4>
                <br>
                <p>To set a new password, enter the old password, and then enter the new password twice.</p> 
                <p>The password must be at least 8 characters long and contain at least one uppercase letter and one digit or special character.</p>

                <v-text-field prepend-inner-icon="mdi-lock"
                    v-model="passwords.old.value"
                    type="password"
                    label="Old password"
                    :rules="[validationRules.passwordOld]"
                    :error-messages="passwords.old.errorMessages"
                    @input="passwords.old.errorMessages = ''"
                    color="rgba(4, 202, 90, 1)"></v-text-field>

                <v-text-field prepend-inner-icon="mdi-lock"
                    v-model="passwords.new.value"
                    type="password"
                    label="New password"
                    :rules="[validationRules.newPassword]"
                    :error-messages="passwords.new.errorMessages"
                    @input="passwords.new.errorMessages = ''; validateRepeatPassword();"
                    color="rgba(4, 202, 90, 1)"></v-text-field>
                
                <v-text-field prepend-inner-icon="mdi-lock"
                    v-model="passwords.confirmNew.value"
                    type="password"
                    label="Repeat new password"
                    :rules="[validationRules.repeatNewPassword]"
                    :error-messages="passwords.confirmNew.errorMessages"
                    @input="passwords.confirmNew.errorMessages = ''"
                    color="rgba(4, 202, 90, 1)"></v-text-field>
            </v-flex>

            <v-flex xs12 style="display: flex; padding: 5px 30px 10px 30px;">
                <v-row justify="end">
                    <v-btn outlined @click="$router.go(-1)" class="right" min-width="120">
                        Cancel
                    </v-btn>

                    &nbsp;
                    <v-btn color="primary" @click="saveChanges" class="right" min-width="120">
                        Save
                    </v-btn>
                </v-row>
                &nbsp;
            </v-flex>
            
        </v-card>

        <v-snackbar
        v-model="passwordUpdateNotifier"
		fixed
		class="snackbar-offline-notifier"
        :color="snackbarColor"
        >
			<v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
                <div>{{ passwordUpdateNotification }}</div>
            </v-flex>
        </v-snackbar>

        <v-overlay :value="overlayNotifier">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        </div>
	</div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'ChangePassword',

	data: function(){
        return {
            title: "Change password",
            user: "",
            overlayNotifier: false,
            passwords: {
                old: {
                    value: "",
                    errorMessages: ""
                },
                new: {
                    value: "",
                    errorMessages: ""
                },
                confirmNew: {
                    value: "",
                    errorMessages: ""
                }
            },
            passwordUpdateNotifier: false,
            passwordUpdateNotification: "",
            snackbarColor: "",

            validationRules: {
                passwordOld: value => {
                    if (value.length == 0){
                        return true;
                    }
                    let isOK = this.validatePassword(value);
                    return isOK || "Invalid password structure";
                },
                newPassword: value => {
                    if(value.length == 0){
                        return true;
                    }
                    let isOK = this.validatePassword(value);
                    return isOK || "Invalid password structure";
                },
                repeatNewPassword: value => {
                    return value == this.passwords.new.value || "Passwords do not match";
                }
            },

            errors: {
                oldPassword: ""
            }
        }
	},
    props: {

    },
	mounted(){
        if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
            return
		}
        this.$emit("set-state", "default");
        this.setTitle(this.title)
        this.getGoBackLink()
        this.user=localStorage.username
	},
    methods: {
        setTitle() {
			this.$emit('set-title', this.title)
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "/settings");
		},
        saveChanges: async function(){
            if (this.passwords.new.value != this.passwords.confirmNew.value ||
                this.passwords.old.value.length == 0 ||
                this.passwords.new.value.length == 0 ||
                this.passwords.confirmNew.value.length == 0 ||
                this.passwords.new.value == this.passwords.old.value){

                if(this.passwords.new.value.length == 0){
                    this.passwords.new.errorMessages = "New password not provided";
                }
                if(this.passwords.old.value.length == 0){
                    this.passwords.old.errorMessages = "Old password not provided";
                }
                if(this.passwords.confirmNew.value.length == 0 || this.passwords.new.value != this.passwords.confirmNew.value){
                    this.passwords.confirmNew.errorMessages = "Incorrectly repeated password";
                }
                if(this.passwords.new.value == this.passwords.old.value){
                    this.passwords.new.errorMessages = "New password cannot be the same as old one";
                }
                return;
            }

            this.passwords.new.errorMessages = "";
            this.passwords.old.errorMessages = "";
            this.passwords.confirmNew.errorMessages = "";

            let formData = new FormData();

            formData.append('old_password', this.passwords.old.value);
            formData.append('password', this.passwords.new.value);
            formData.append('password2', this.passwords.confirmNew.value);

            this.overlayNotifier = true;
            axios({
                method: 'POST',
                url: appConfig.getApiUrl(null) + "/api/v1/change-password/",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
                data: formData
            }).then(() => {
                this.passwordUpdateNotifier = true;
                this.passwordUpdateNotification = "Password updated successfully";
                this.snackbarColor = "success";
                // redirect to settings page after 1 seconds
                setTimeout( () => {
                    this.passwords.old.value = "";
                    this.passwords.new.value = "";
                    this.passwords.confirmNew.value = "";
                    this.passwords.old.errorMessages = "";
                    this.passwords.new.errorMessages = "";
                    this.passwords.confirmNew.errorMessages = "";
                    this.overlayNotifier = false; 
                    this.$router.push({name: 'settings'})
                }, 1000);
            }).catch(error => {
                console.log(error.response);
                this.overlayNotifier = false;
				this.passwordUpdateNotifier = true;
				this.passwords.old.value = "";
                this.passwords.new.value = "";
                this.passwords.confirmNew.value = "";
                this.snackbarColor = "red darken-2";

                if (error.isAxiosError){
                    if(error.response.status == 400){
                        if(error.response.data.old_password && error.response.data.old_password[0].code=="OLD_PASSWORD_INCORRECT"){
							this.passwords.old.errorMessages = "Incorrect old password";
							this.passwordUpdateNotification = "Could not update"
						}
						else if(error.response.data.password){
							for(let item of error.response.data.password){
								if(item.code=="password_too_common"){
									this.passwords.new.errorMessages = "This password is too common.";
									this.passwordUpdateNotification = "Could not update"
								}
							}
						}
						else{
							this.passwordUpdateNotification = "Could not update"
						}
                    }
                    else if(error.response.status == 403 || error.response.status == 401){
                        this.passwordUpdateNotification = "Could not update";
                    }
                    else if(error.response.status == 500){ 
                        this.passwordUpdateNotification = "Server error";
                    }
                    else{
                        this.passwordUpdateNotification = "Unknown error";
                    }
                }
            });
        },
        validatePassword: function(value){
            let specialSigns = "@$!%*?&#";
            let checks = {l: false, u: false, d: false, s: false};
            for(var i = 0; i < value.length; i++){
                if (specialSigns.includes(value[i])){
                    checks.s = true;
                } else if ('0' <= value[i] && value[i] <= '9'){
                    checks.d = true;
                } else if ('a' <= value[i] && value[i] <= 'z'){
                    checks.l = true;
                } else if ('A' <= value[i] && value[i] <= 'Z'){
                    checks.u = true;
                }
            }
            let isOK = (value.length >= 8) && checks.u && (checks.d || checks.s) && checks.l;
            return isOK;
        },
        validateRepeatPassword: function(){
            if (this.passwords.old.value != ''){
                if (this.passwords.new.value == this.passwords.old.value){
                    this.passwords.new.errorMessages = "New password cannot be the same as old one";
                }
                else{
                    this.passwords.new.errorMessages = '';
                }
            }
            let password = this.passwords.new.value;
            let passwordRepeat = this.passwords.confirmNew.value;
            if (password == passwordRepeat){
                this.passwords.confirmNew.errorMessages = "";
            }
            else {
                this.passwords.confirmNew.errorMessages = "Incorrectly repeated password";
            }
        }	
    }
	};
</script>

<style scoped>

.snackbar-offline-notifier{
    height: 30px !important;
    margin-bottom: 70px;
    top: calc(90% - 35px) !important;
    -webkit-font-smoothing: antialiased; 
    text-rendering: geometricPrecision;
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    transition: 0.5s !important;
    background-color: var(--v-primary-base);
}


</style>
